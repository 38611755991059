export class OrgSetupModel {
  ID?: number;
  OrgName: string;
  OrgTypeID: number;
  PrimaryContactID: number;
  Address1?: string;
  Address2?: string;
  City?: string;
  State?: string;
  Zip?: string;
  Country?: string;
  PrimaryPhone?: string;
  ContactEmail?: string;
  Website?: string;
  Deleted?: boolean;
}

export class InviteUserEventOrg {
  OrgID: number;
  Email: string;
  Result: boolean;
}

export class InviteUserEventProj {
  OrgID: number;
  ProjID: number;
  Email: string;
  Result: boolean;
}
